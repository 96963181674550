import React, { useEffect, useRef } from "react";
import { Flex, Text } from "@chakra-ui/core";
import { Link } from "gatsby";
import PropTypes from "prop-types";

const customs = {
  0: {
    bg: "#eb3978",
    mainFlex: "row",
    titleFlex: "row-reverse",
    textAlign: "right",
    tapeColor: "rgba(207, 63, 63, 0.5)",
    tapePosition: "8.5rem",
  },
  2: {
    bg: "#3a6ea6",
    mainFlex: "row",
    titleFlex: "row-reverse",
    textAlign: "right",
    tapeColor: "rgba(58, 110, 166, 0.5)",
    tapePosition: "8.5rem",
  },
  1: {
    bg: "#0d2440",
    mainFlex: "row-reverse",
    titleFlex: "row",
    textAlign: "left",
    tapeColor: "rgba(0, 0, 0, 0.5)",
    tapePosition: "29rem",
  },
};

const PostCard = ({
  title,
  slug,
  custom,
  prevTrigger,
  loadPrev,
  nextTrigger,
  loadNext,
  flipSides,
  flip,
  author,
  excerpt,
}) => {
  const el = useRef(null);
  const name = slug.slice(7, slug.length - 1);
  useEffect(() => {
    if (prevTrigger) {
      el.current.classList.add("animate__fadeOut");
      setTimeout(() => {
        flipSides(false);
        loadPrev();
        window.scrollTo({ top: 0 });
      }, 1000);
    }
    if (nextTrigger) {
      el.current.classList.add("animate__fadeOut");
      setTimeout(() => {
        flipSides(true);
        loadNext();
        window.scrollTo({ top: 0 });
      }, 1000);
    }
  }, [loadPrev, prevTrigger, loadNext, nextTrigger, flipSides]);

  const style = {
    backgroundColor: customs[custom].bg,
    borderRadius: 5,
    color: "white",
    padding: "2rem",
    marginTop: "2rem",
    marginBottom: "2rem",
  };
  return (
    <Link to={slug}>
      <Flex
        className={`animate__animated ${
          flip ? "animate__fadeIn" : "animate__fadeIn"
        } ${custom ? `animate__delay-${custom + 1}s` : ""}`}
        flexDirection={["column-reverse", customs[custom].mainFlex]}
        justifyContent="space-between"
        alignItems={["center", "initial"]}
        style={style}
        height={["auto", "16rem"]}
        position={"relative"}
        ref={el}
      >
        <Flex
          flexDirection="column"
          alignItems={["center", "initial"]}
          justifyContent="space-between"
          w={["auto", "50%"]}
        >
          <Flex flexDirection={customs[custom].titleFlex}>
            <Text
              textAlign={["center", customs[custom].textAlign]}
              fontSize={"1.2rem"}
              fontWeight={500}
              fontFamily={"Playfair Display, serif"}
              as="h2"
              mt={[3, 0]}
            >
              {title}
            </Text>
          </Flex>
          <Text
            textAlign={[
              "center",
              customs[custom].textAlign === "left" ? "right" : "left",
            ]}
            mt={[2, 0]}
          >
            {excerpt}
          </Text>
        </Flex>
        {/* <div
          style={{
            backgroundColor: customs[custom].tapeColor,
            width: "3.6rem",
            height: "1.4rem",
            position: "absolute",
            right: customs[custom].tapePosition,
            top: "1.3rem",
          }}
        ></div> */}
        <Flex
          p={"2rem"}
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          bg={"#fff"}
          w={["auto", "46%"]}
        >
          <img src={`/images/posts/${name}.jpeg`} alt={name} />
          <Text
            color="black"
            textAlign={"center"}
            fontFamily={"Raleway, sans-serif"}
            mt={"0.6rem"}
            fontSize={15}
          >
            -{author.split(",")[0]}
          </Text>
        </Flex>
      </Flex>
    </Link>
  );
};

export default PostCard;

PostCard.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  custom: PropTypes.number.isRequired,
  prevTrigger: PropTypes.bool.isRequired,
  loadPrev: PropTypes.func.isRequired,
  nextTrigger: PropTypes.bool.isRequired,
  loadNext: PropTypes.func.isRequired,
  flipSides: PropTypes.func.isRequired,
  flip: PropTypes.bool.isRequired,
  author: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
};
