import React from "react";
import { Flex, Box, Text } from "@chakra-ui/core";
import PropTypes from "prop-types";

export default function SidebarHeader({ children, width }) {
  const frame = {
    width,
    height: 3,
    // backgroundColor: "rgba(0, 0, 0, 0.5)",
    backgroundColor: "#ff9048",
    borderRadius: 10,
  };
  return (
    <Flex alignItems="center" justifyContent="center">
      <Box bg={"black"} style={frame}></Box>
      <Text mx={3} letterSpacing={2}>
        {children}
      </Text>
      <Box bg={"black"} style={frame}></Box>
    </Flex>
  );
}

SidebarHeader.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.number.isRequired,
};
