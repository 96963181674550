import React from "react";
import { Flex, Text } from "@chakra-ui/core";
import SidebarHeader from "./SidebarHeader";
import Socials from "./Socials";
// import { css } from "@emotion/core"

export default function Sidebar() {
  return (
    <Flex
      style={{
        height: 310,
        marginTop: "2rem",

        // backgroundColor: "rgba(244, 240, 236, 0.8)",
        borderRadius: 10,
        border: "1px solid",
        borderColor: "#ff9048",
        padding: "1.3rem 2rem",
      }}
      className="spacing"
      width={["18.75rem"]}
      alignItems="center"
      flexDirection="column"
      display={["none", "none", "flex"]}
    >
      <SidebarHeader width={43}>SUBMISSIONS</SidebarHeader>
      <Text
        mt="1rem"
        mb="1.2rem"
        fontFamily={"Playfair Display, serif"}
        fontSize={15}
        textAlign="center"
      >
        If you are a school student, we would love to hear your thoughts on just
        about anything. Send us your articles at{" "}
        <a
          rel="noreferrer"
          href="mailto:blog@aawaaz.co"
          target="_blank"
          style={{ color: "#3a6ea6" }}
        >
          blog@aawaaz.co
        </a>
      </Text>
      <SidebarHeader width={67}>FIND US</SidebarHeader>
      <Text
        m={"1rem 0"}
        fontFamily="Raleway, sans-serif"
        color="#0d2440"
        fontSize={16}
        // css={css`
        //   &:hover {
        //     text-decoration: underline;
        //   }
        // `}
      >
        <a href="https://aawaaz.co">Aawaaz Education Services</a>
      </Text>
      <Socials w={30} show={"flex"} />
    </Flex>
  );
}
