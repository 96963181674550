import React from "react";
import logo from "../../static/images/other/logo.png";
import { Link } from "gatsby";
import { css, Flex, Text } from "@chakra-ui/core";
import Footer from "./footer";
import PropTypes from "prop-types";

export default function Layout({ children, socials = false }) {
  let url = typeof window !== "undefined" ? window.location.href : "NA";
  if (url !== "NA") {
    const urlSegments = url.split("/");
    url = urlSegments[urlSegments.length - 1];
  }
  return (
    <Flex
      flexDirection="column"
      width={["80vw", "42rem", "64rem"]}
      margin={["3rem auto"]}
    >
      <header>
        <Flex
          flexDirection={["column", "column", "row"]}
          alignItems={["center"]}
          justifyContent="center"
        >
          <img width={80} height={80} src={logo} alt="Logo" />
          <Flex
            ml={3}
            direction="column"
            alignItems={["center", "center", "flex-start"]}
          >
            <Text
              fontFamily={"Playfair Display, serif"}
              fontWeight={600}
              fontSize={[32]}
              as="h1"
              textAlign="center"
            >
              <Link to="/">The Aawaaz Blog</Link>
            </Text>
            <Text fontSize={[18]} textAlign={["center", "left"]}>
              The perfect platform to showcase your Aawaaz
            </Text>
          </Flex>
        </Flex>
      </header>
      {(!url || url === "student") && (
        <nav
          css={css`
            display: flex;
            justify-content: center;
            margin-top: calc(1.5rem + 3px);
            font-size: 18px;
            transition: border-bottom-color 2s;
            & > .nav-item {
              border-bottom: 3px solid transparent;
            }
            & > .nav-item:hover {
              border-bottom-color: #ff9048;
            }
          `}
        >
          <Link
            className="nav-item"
            to="/"
            style={{ borderBottomColor: !url ? "#ff9048" : "" }}
          >
            Home
          </Link>
          <Link
            to="/student"
            className="nav-item"
            style={{
              marginLeft: "5rem",
              borderBottomColor: url === "student" ? "#ff9048" : "",
            }}
          >
            Student Blogs
          </Link>
        </nav>
      )}
      <Flex justifyContent="space-between">{children}</Flex>
      <Footer post={socials} />
    </Flex>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  socials: PropTypes.bool,
};
